import { CSSTransition } from "react-transition-group";
import HeroSection from "./HeroSection";
import { Carousel } from "antd";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import translations from "../../data/translations"; // Update the import path as needed
import { useLanguage } from "../LanguageProvider";
import {
  InstagramOutlined,
  MailOutlined,
  MobileOutlined,
  StarFilled,
  StarTwoTone,
} from "@ant-design/icons";

export default function RetreatNepal() {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <ScrollToTop />
      <HeroSection page="retreat-np" classname="retreat-np" title="" />
      <section id="AboutMe" className="container my-4 about-section">
        <h1
          className="text-center"
          style={{ fontSize: "61px", fontWeight: "600" }}
        >
          KORA RETREAT NEPAL
        </h1>
        <h1 className="text-center" style={{ fontWeight: "600" }}>
          {" "}
          Te souhaite la bienvenue
        </h1>
        <div className="custom-line"></div>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box">
            <p className="text-center" style={{ color: "#212529" }}>
              KORA RETREAT est un séjour culturel et spirituel sur les terre
              sacrées du Népal, bercée tant par <br />
              l’Hindouisme que le Bouddhisme, et enveloppée par la grandeur des
              plus hautes chaînes de <br />
              montagnes au monde. L’idée? Book ton vol et viens t’immerger 15
              jours dans les profondeurs <br />
              du Nepal et de toi même! Es-tu prêt à oser pour une experience qui
              te marquera à tout jamais ?
            </p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div className="row" style={{ marginTop: "39px" }}>
          <div className="col-md-3">
            <img
              src="../img/retreat/np-cu.jpg"
              className="img-fluid rounded"
              style={{ height: "300px", objectFit: "cover" }}
            />
          </div>
          <div className="col-md-6">
            <p style={{ color: "#212529" }}>
              Situé au coeur de l’Himalaya, le Népal t’offrira ce que tu ne
              trouveras nul par ailleurs! Nous irons à la découverte de la
              vallée de Kathmandu, une belle approche de la culture népalaise
              qui permet la visite de nombreux temples. Ensuite nous partirons
              dans le sud direction Pokhara et nous irons au plus près des
              montagne pour un trek facile mais challengeant que tu n’es pas
              prêt d’oublier! Nous nous relaxerons dans une eco-ferme où tu
              seras complètement immerger dans le mode de vie Sherpa! Et puis,
              puisque le NÉPAL est riche de par sa culture hindouisme et
              bouddhiste, nous passerons quelques jours dans un ashram où tu
              auras une initiation au bouddhisme. Ce séjour sera remplie
              d’activité locale :danse traditionnelle, cours de cuisine, visite
              d’usine mais aussi beaucoup de connexion à soi et de relaxation
              avec une pratique du yoga traditionnel quotidienne. Partage,
              émotions, et fun nous accompagnerons tout le long de notre
              retraite!
            </p>
          </div>
          <div className="col-md-3">
            <img
              src="../img/retreat/gr.jpg"
              className="img-fluid rounded"
              style={{ height: "300px", objectFit: "cover" }}
            />
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <div class="wave-container-retreat">
        <svg
          class="wave-retreat"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="my-4" style={{ background: "#a9b9be" }}>
        <div className="container mt-2">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <h1 className="text-center" style={{ fontWeight: "600" }}>
                NOV 2024 <br />
                FEV - NOV 2025
              </h1>
            </div>
            <div className="col-md-4">
              <h1 className="text-center" style={{ fontWeight: "600" }}>
                EUR
                <br />
                2500
              </h1>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-md-6 d-flex justify-content-center">
              <img
                src="../img/retreat/ind.jpg"
                className="img-fluid rounded"
                alt="Yoga Image"
                style={{ height: "710px", objectFit: "cover" }} // Increased height to make the image larger
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <h2 className="font-weight-bold">Inclus dans le prix :</h2>
                <ul>
                  <li>
                    Un road trip de 14 jours préparés sur mesure que tu
                    découvriras par étape en détails au jour le jour!
                  </li>
                  <li>
                    Hôtels 4 étoiles sélectionnés entre Kathmandu et Pokhara
                    pour un total de 14 nuits
                  </li>
                  <li>
                    1 nuit dans un resort 5 étoiles dans les hauteurs de
                    Kathmandu
                  </li>
                  <li>1 nuit dans une éco-ferme dans un village en montagne</li>
                  <li>Petit-déjeuner, déjeuner, snack, dîner pour 15 jours</li>
                  <li>Guide culturel français pendant plusieurs jours</li>
                  <li>
                    Transports aéroport jusqu’au lieu de la retraite
                    aller/retour + tous les déplacements quotidiens en groupe
                    (taxi, car, van, jeep)
                  </li>
                  <li>
                    Guide français et porteurs pendant le trekking en montagne
                  </li>
                  <li>Permis pour le trek</li>
                  <li>
                    Toutes les activités et entrées dans les différents lieux
                    culturels
                  </li>
                  <li>Cours de yoga quotidien</li>
                  <li>Sound bowl Healing thérapie</li>
                  <li>Immersion de 2 jours dans un ashram</li>
                  <li>Et plein de surprises…</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <h2 className="font-weight-bold">Pourquoi Kora retreat ? </h2>
                Dérivé du mot tibétain khor, qui signifie révolution. Le Kora
                est un rituel sacré qui consiste à marcher autour d'un site
                vénéré, généralement dans le sens des aiguilles d'une montre,
                comme une forme de dévotion, de méditation et de pratique
                spirituelle. Dans la tradition bouddhiste tibétaine, le Kora a
                une profonde signification culturelle. On pense qu'il purifie le
                karma négatif, accumule du mérite et apporte une transformation
                spirituelle aux pratiquants. Alors nous effectuerons notre Kora
                tous ensemble avec un programme complet remplie de surprise.
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center mt-4">
              <img
                src="../img/retreat/gi.jpg"
                className="img-fluid rounded"
                alt="Yoga Image"
                style={{ height: "600px", objectFit: "cover" }} // Increased height to make the image larger
              />
            </div>
          </div>
        </div>
      </div>

      <div className="wave-container-portfolio">
        <svg
          className="wave-portfolio"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
          style={{ background: "#a9b9be" }}
        >
          <path
            fill="#f1ebeb"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center" style={{ fontWeight: "600" }}>
          Kora retreat est LE voyage idéal si tu es en <br />
          quête de spiritualité et si tu aimes les voyages à la <br />
          découverte de cultures ancestrales.
        </h1>
        <div className="row p-5"></div>
      </section>
      <div class="wave-container-retreat1">
        <svg
          class="wave-retreat1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div
        className="my-4"
        style={{ background: "#a9b9be", marginTop: "34px" }}
      >
        <div className="container mt-2">
          <h1 className="text-center" style={{ fontWeight: "600" }}>
            Rejoins-nous pour l’aventure de toute une vie!
          </h1>
          <p className="text-center" style={{ color: "#212529" }}>
            Contactez-nous pour obtenir plus d’informations et pour effectuer
            une réservation.
          </p>
          <div className="row ml-4">
            <div className="col-md-4 text-center">
              <a
                href="https://www.instagram.com/kora_retreat_nepal?igsh=cTJ6cGpvNm1kcXU0&utm_source=qr"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <InstagramOutlined
                  style={{ fontSize: "67px", color: "#212529" }}
                />
                <br />
                <h6 className="text-center mt-2" style={{ color: "#212529" }}>
                  {" "}
                  kora_retreat_nepal
                </h6>
              </a>
            </div>
            <div className="col-md-4 text-center">
              <MobileOutlined style={{ fontSize: "67px", color: "#212529" }} />
              <br />
              <h6 className="text-center mt-2"> (+33) 611931427</h6>
            </div>
            <div className="col-md-4 text-center">
              <MailOutlined style={{ fontSize: "67px", color: "#212529" }} />
              <br />
              <h6 className="text-center mt-2"> yogabykora@gmail.com</h6>
            </div>
          </div>
          <div className="row p-5"></div>
        </div>
      </div>
      <section className="partner-logos">
        <div className="container text-center">
          <h1 className="text-center mb-4" style={{ fontWeight: "600" }}>
            Our Partners
          </h1>
          <div className="custom-line"></div>
          <div className="row justify-content-center align-items-center">
            <div className="col-6 col-md-4 mb-4">
              <img src="../img/du.png" alt="Partner 1 Logo" className="img-fluid" />
            </div>
            <div className="col-6 col-md-4 mb-4">
              <img
                src="../img/kr.png"
                alt="Partner 2 Logo"
                className="img-fluid"
              />
            </div>
            <div className="col-6 col-md-4 mb-4">
              <img src="../img/hn.png" alt="Partner 3 Logo" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
